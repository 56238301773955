import c from "classnames";

import Styles from "./reviews.module.scss";
import { useReviews } from "./useReviews";
import { ReviewItem } from "./ReviewItem";

import { Carousel } from "../Carousel";
import { SplitHeading } from "../FormElements/SplitHeading";
import { Trustpilot } from "../Trustpilot/Trustpilot";

type Props = {
	hasPet?: boolean;
	showHeading?: boolean;
	headingSize?: "normal" | "large";
	showReviewCount?: boolean;
};

export const Reviews = ({
	hasPet = true,
	showHeading = true,
	showReviewCount = false,
}: Props) => {
	const { reviews } = useReviews({ hasPet });
	return (
		<div className={c(Styles.content)}>
			{showHeading && (
				<>
					<SplitHeading
						line1="We've helped"
						line2="thousands of dogs"
						className={Styles.reviewsHeading}
						colour="dark"
					/>
					<Trustpilot centered showReviewCount={showReviewCount} />
				</>
			)}

			<Carousel items={reviews} ItemComponent={ReviewItem} />
		</div>
	);
};
