import c from "classnames";
import slugify from "react-slugify";

import { BenefitsBarSection } from "@/types/page";
import { Icon } from "@/components/FormElements/Icon";
import { SplitHeading } from "@/components/FormElements/SplitHeading";
import { Button } from "@/components/FormElements/Button";
import { useMediaQueries } from "@/hooks/useMediaQueries";

import Styles from "./benefitsBar.module.scss";

type Props = {
	content: BenefitsBarSection;
};

export const BenefitsBar = ({ content }: Props) => {
	const { isMobile } = useMediaQueries();

	return (
		<section
			id={content.id}
			className={c(Styles.benefitsBar, {
				[Styles["border-" + slugify(content.border)]]: content.border,
				[Styles["background-" + slugify(content.backgroundColour || "purple")]]:
					content.backgroundColour,
			})}
		>
			<div className={c("container")}>
				{content?.heading?.[0]?.headingLine1 && (
					<>
						{content.heading[0].split ? (
							<SplitHeading
								heirarchy={content.heading[0].sizing}
								line1={content.heading[0].headingLine1}
								line2={content.heading[0].headingLine2 || ""}
								colour={content.heading[0].secondaryLineColour}
							/>
						) : (
							<h2>{content.heading[0].headingLine1}</h2>
						)}
					</>
				)}

				{(content?.iconPoints?.length || 0) > 1 && (
					<div
						className={c(Styles.benefitsCont, {
							[Styles.benefits3]: content?.iconPoints?.length === 3,
							[Styles.verticalStack]: content?.verticalStack,
						})}
					>
						{content?.iconPoints?.[0]?.icon &&
							content.iconPoints?.map((iconPoint, key) => (
								<div
									className={c(Styles.iconPoint, {
										[Styles.leftAligned]: content.leftAlignedIcons && isMobile,
									})}
									key={key}
								>
									{iconPoint.heading &&
										(!content.leftAlignedIcons || !isMobile) && (
											<h4>{iconPoint.heading}</h4>
										)}

									<Icon
										icon={iconPoint.icon}
										height="48"
										width="48"
										noFill={true}
									/>

									<div>
										{iconPoint.heading &&
											content.leftAlignedIcons &&
											isMobile && <h4>{iconPoint.heading}</h4>}

										{iconPoint.caption && (
											<span className={Styles.caption}>
												{iconPoint.caption}
											</span>
										)}

										{iconPoint.text && <span>{iconPoint.text}</span>}
									</div>

									{!content.squareCorners && (
										<span className={Styles.cutOutTop}></span>
									)}

									{!content.squareCorners && (
										<span className={Styles.cutOutBottom}></span>
									)}
								</div>
							))}
					</div>
				)}

				{content?.button?.[0]?.text && (
					<Button
						url={content.button[0].link}
						width="relative"
						sizing="large"
						className={Styles.ctaButton}
					>
						{content.button[0].text}
					</Button>
				)}
			</div>
		</section>
	);
};
