import { LegacyRef } from "react";

import c from "classnames";

import Styles from "./reviews.module.scss";

import { Icon } from "../FormElements/Icon";

export type ReviewItemProps = {
	name: string;
	alt: string;
	image: string;
	content: string;
	title: string;
	forwardRef: LegacyRef<HTMLDivElement>;
};

export const ReviewItem = ({
	alt,
	content,
	image,
	name,
	title,
	forwardRef,
}: ReviewItemProps) => {
	return (
		<div ref={forwardRef} className={Styles.itemWrapper}>
			<div className={c(Styles.item)}>
				<div className={Styles.image}>
					<span className={Styles.initials}>
						{name
							.split(" ")
							.map((n) => n[0])
							.join("")}
					</span>
				</div>
				<div className={Styles.imageContent}>
					<div className={Styles.name}>{name}</div>
					<div className={Styles.stars}>
						<Icon icon="Star" />
						<Icon icon="Star" />
						<Icon icon="Star" />
						<Icon icon="Star" />
						<Icon icon="Star" />
					</div>
				</div>
				<div className={Styles.title}>{title}</div>
				<p className={Styles.content}>{content}</p>
			</div>
		</div>
	);
};
