import { useRef, useState } from "react";

import c from "classnames";

import Styles from "./trustpilot.module.scss";

type Props = {
	centered?: boolean;
	light?: boolean;
	showReviewCount?: boolean;
};

declare global {
	interface Window {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		Trustpilot: any;
	}
}

export const Trustpilot = ({
	centered = false,
	light = false,
	showReviewCount = false,
}: Props) => {
	const trustBox = useRef(null);
	const [isLoaded, setIsLoaded] = useState(false);

	if (
		typeof window !== "undefined" &&
		window.Trustpilot &&
		trustBox.current &&
		!isLoaded
	) {
		window.Trustpilot.loadFromElement(trustBox.current, true);
		setIsLoaded(true);
	}

	return (
		<div style={{ position: "relative" }}>
			{!showReviewCount && (
				<div
					className={c(
						Styles.trustpilot,
						{ [Styles.center]: centered },
						{ [Styles.loading]: !isLoaded },
						"trustpilot-widget"
					)}
					ref={trustBox}
					data-locale="en-GB"
					data-template-id="5419b637fa0340045cd0c936"
					data-businessunit-id="5509a7a10000ff00057e23c1"
					data-style-height="20px"
					data-style-width="100%"
					data-theme={!light ? "light" : "dark"}
				>
					<a
						href="https://uk.trustpilot.com/review/purepetfood.com"
						target="_blank"
						rel="noreferrer"
					>
						Trustpilot
					</a>
				</div>
			)}
			{showReviewCount && (
				<div
					className={c(
						Styles.trustpilot,
						{ [Styles.showReviewCount]: showReviewCount },
						{ [Styles.center]: centered },
						{ [Styles.loading]: !isLoaded },
						"trustpilot-widget"
					)}
					ref={trustBox}
					data-locale="en-GB"
					data-template-id="5419b6ffb0d04a076446a9af"
					data-businessunit-id="5509a7a10000ff00057e23c1"
					data-style-height={"40px"}
					data-style-width="100%"
					data-theme={!light ? "light" : "dark"}
				>
					<a
						href="https://uk.trustpilot.com/review/purepetfood.com"
						target="_blank"
						rel="noreferrer"
					>
						Trustpilot
					</a>
				</div>
			)}
		</div>
	);
};
