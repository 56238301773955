import c from "classnames";
import slugify from "react-slugify";

import { Reviews } from "@/components/Reviews";
import { ReviewsSections } from "@/types/page";

import Styles from "./reviewsSection.module.scss";

type Props = {
	content: ReviewsSections;
	hasPet: boolean;
};

export const ReviewsSection = ({ content, hasPet = false }: Props) => {
	return (
		<section
			id={content.id}
			className={c("sectionSpacing", Styles.reviews, {
				[Styles["background-" + slugify(content.backgroundColour)]]:
					content.backgroundColour,
				[Styles.headingLarge]: !hasPet,
			})}
		>
			<div className="container">
				<Reviews
					hasPet={hasPet}
					showHeading={content.showHeading}
					showReviewCount={content.showReviewCount}
				/>
			</div>
		</section>
	);
};
