import { FeatureList, recipeFeaturesList, successStoriesData } from "../data";
import { Pet } from "../types";

const addThisContent = (feature: FeatureList, pet: Pet) => {
	const [pro1, pro2, pro3] =
		pet.gender === "female" ? ["she", "her", "her"] : ["he", "his", "him"];

	const allergies = Array.isArray(pet?.allergies)
		? pet?.allergies?.join(", ")
		: pet?.allergies || "";

	const weightWording = pet.shape === "just right" ? "maintain" : "achieve";

	const replacements = [
		pet.name,
		pro1,
		pro2,
		`${pet.scoopsFood}`,
		allergies,
		weightWording,
		pro3,
	];

	const content = feature.content.replace(
		/%(\d+)/g,
		(_, n) => replacements[+n - 1]
	);

	const title = feature.title.replace(
		/%(\d+)/g,
		(_, n) => replacements[+n - 1]
	);

	return { title: title, content: content, details: feature.details };
};

export function getSuccessStories(pet: Pet | null) {
	const chosenReviews = [];
	const nonChosenReviews = [];

	if (pet) {
		const overweight =
			pet.shape === "a bit chubby" || pet.shape === "very overweight";
		const underweight = pet.shape === "a little skinny";
		const fussy = pet.fussy !== "isn't fussy";

		for (const review of successStoriesData) {
			if (chosenReviews.length >= 9) {
				break;
			}

			if (pet?.healthIssues && pet.healthIssues.length > 0) {
				for (const healthIssue of pet.healthIssues) {
					if (review.tags.includes(healthIssue)) {
						chosenReviews.push(review);
						continue;
					}
				}
			}

			if (fussy && review.tags.includes("fussy")) {
				chosenReviews.push(review);
			} else if (overweight && review.tags.includes("overweight")) {
				chosenReviews.push(review);
			} else if (underweight && review.tags.includes("underweight")) {
				chosenReviews.push(review);
			} else {
				nonChosenReviews.push(review);
			}
		}
	} else {
		for (const review of successStoriesData) {
			nonChosenReviews.push(review);
		}
	}

	if (chosenReviews.length < 9) {
		for (const nonChosen of nonChosenReviews) {
			if (chosenReviews.length >= 9) {
				break;
			}
			chosenReviews.push(nonChosen);
		}
	}

	for (let i = chosenReviews.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[chosenReviews[i], chosenReviews[j]] = [chosenReviews[j], chosenReviews[i]];
	}

	return chosenReviews;
}

export const recipeFeatures = (
	petVars: Pet | null,
	multiplePets: boolean = false
) => {
	if (!petVars) {
		return [];
	}

	if (multiplePets) {
		return [
			{
				title: "Investing in their future",
				content:
					"Pure includes gut friendly ingredients and antioxidants so you can invest in their ongoing health",
			},
			{
				title: "Perfectly portioned",
				content:
					"Crafted with your pets in mind, their tasty Pure will be lovingly hand-prepared here in Yorkshire.",
			},
		];
	}

	const petAgeType =
		petVars?.age < 12 ? "puppy" : petVars?.age > 84 ? "senior" : "adult";
	const isFussy = ["a real fusspot", "a little picky"].includes(petVars.fussy);
	const condition = petVars.shape;
	const healthIssues = petVars.healthIssuesLong || petVars.healthIssues || [];
	const healthQuestions = [
		(petVars?.coat?.filter((val) => val !== "none").length || 0) > 0 && "coat",
		(petVars?.digestion?.filter((val) => val !== "none").length || 0) > 0 &&
			"digestion",
		(petVars?.behavioural?.filter((val) => val !== "none").length || 0) > 0 &&
			"behavioural",
		(petVars?.mobility?.filter((val) => val !== "none").length || 0) > 0 &&
			"mobility",
		(petVars?.oral?.filter((val) => val !== "none").length || 0) > 0 && "oral",
	];
	const stools = petVars.stools;

	let chosenFeatures = 0;

	return recipeFeaturesList
		.filter((feature) => {
			if (chosenFeatures === 2) {
				return false;
			}

			const matchesAge =
				feature.age?.length > 0 && feature.age?.includes(petAgeType);
			const matchesAllergies =
				feature.allergies && petVars?.allergies?.length > 0;
			const matchesHealthIssues =
				feature.ailments?.length > 0 &&
				healthIssues?.length > 0 &&
				healthIssues?.some((healthIssue) =>
					feature.ailments.includes(healthIssue)
				);
			const matchesHealthQuestions =
				feature.health?.length > 0 &&
				healthQuestions?.length > 0 &&
				healthQuestions?.some(
					(healthIssue) => healthIssue && feature.health.includes(healthIssue)
				);
			const matchesStools =
				feature.stools.length > 0 && stools && feature.stools.includes(stools);
			const matchesFussy = feature.fussy && isFussy;
			const matchesSize = feature.size === condition;

			if (
				matchesAge ||
				matchesAllergies ||
				matchesHealthIssues ||
				matchesFussy ||
				matchesSize ||
				matchesHealthQuestions ||
				matchesStools
			) {
				chosenFeatures++;
				return true;
			}

			return false;
		})
		.map((feature) => addThisContent(feature, petVars));
};
