import { useMemo } from "react";

import { getSuccessStories } from "@/helpers/recipeContentHelper";
import { useUserDataStore } from "@/store/UserDataStore";

type Props = {
	hasPet: boolean;
};

export const useReviews = ({ hasPet }: Props) => {
	const [activePetId, user] = useUserDataStore((state) => [
		state.activePetId,
		state.user,
	]);

	const activePet = user?.pets?.[activePetId || 0];

	const reviews = useMemo(
		() =>
			getSuccessStories((hasPet && activePet) || null).map((item) => ({
				...item,
				id: item.title,
			})),
		[activePet, hasPet]
	);

	return { reviews };
};
